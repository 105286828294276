import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import ShowInfo from "../../../../components/Showinfo/ShowInfo";
import Header from "../../../../components/Layout/Header/HeaderSubmitBill";
import { headerScreenLabels, apiData } from "../../../../common/common-types";
import MyRoute from "../../../../hoc/MyRoute";
import AlertMessage, {
  openAlertBox,
} from "../../../../common/AlertMessage/AlertMessage";
import {
  changeActiveTab,
  logoutUser,
  showLoader,
} from "../../../../../src/App/redux/action";
import { stockAPI } from "../../../../common/axiosInstance";

const styles = (theme) => {
  return {
    terms_container: {
      "& p": {
        margin: "0 !important",
      },
    },
  };
};
class TermsOfUse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terms: " ",
    };
  }
  async componentDidMount() {
    this.props.changeActiveTab(4);
    // if (getStorageItem("user")) {
    // this.props.showLoader(true);
    const formData = new FormData();
    formData.append("platform", apiData.platform);
    formData.append("merchant_id", apiData.merchant_id);
    formData.append("page_type", "304");
    let url =
      "/getTermsCondition" 
    await stockAPI(formData, "POST", url, null, null, undefined, true).then((res) => {

      // this.props.showLoader(false);
      if (res.statusCode === 100 && res.response) {
        this.setState({ terms: res.response });
      } else {
        openAlertBox("Oops something went wrong!", "error");
      }
    });
    // } else {
    //   this.props.logoutUser();
    // }
  }
  render() {
    const { terms } = this.state;
    const { classes } = this.props;
    if (
      !this.props.isUserVisited &&
      this.props.history.pathname === "/registration"
    ) {
      this.props.history.replace("/");
    }
    return (
      <Fragment>
        <Header screenLabel={headerScreenLabels.termsOfUse} />
        <AlertMessage />
        <MyRoute
          fullHeight={true}
          billUploadStatus={this.props.uploadingStatus}
        >
          <div className={classes.terms_container}>
            <ShowInfo data={terms} />
          </div>
        </MyRoute>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  changeActiveTab,
  logoutUser,
  showLoader,
};
const mapStateToProps = (state) => {
  return {
    isUserVisited: state.reducerOtpverification.isUserVisited,
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })(TermsOfUse)));
